import React, {useState, useEffect, useCallback} from 'react'
import {
    Button,
    Card,
    TextField,
    Select,
    Stack,
    Tag,
    Icon,
    Modal,
    TextContainer,
    TextStyle,
    Spinner,
    OptionList,
    FormLayout,
    Checkbox,
} from "@shopify/polaris"
import {
    DeleteMajor,
    SearchMajor
  } from '@shopify/polaris-icons';
import gql from "graphql-tag"
import { useLazyQuery } from 'react-apollo';
import styled from 'styled-components'
import update from 'immutability-helper'
import MulProductPickerModal from '../../../components/mulProductPickerModal'

const TextWrapper = styled.div`
    span {
      line-height: 36px;
    }
`
const ProductPicker = styled.div`
    .mockInputContainer-error {
        background-color: var(--p-surface-critical-subdued);
        border-color: var(--p-border-critical);
    }
`
const IfThenLabel = styled.div`
    .Polaris-Button {
        background: #1D7BBF;
        width: 65px;
        color: #fff;
    }
`
const GET_COLLECTION_PRODUCTS = gql`
        query Nodes($ids: [ID!]!){
            nodes(ids: $ids) {
            ...on Collection {
                id,
                products(first:100){
                    edges {
                        node {
                        id,
                        options {
                            name,
                            values
                        }
                        }
                    }
                },
            }
            }
        }
`
function AIVariantsTriggers(props) {
    const { host, shop, token, state, setState, productPickerShow, setProductPickerShow } = props
    const [collectionPickerVisible, setCollectionPickerVisible] = useState(false)
    const [mulProductPickerVisible, setMulProductPickerVisible] = useState(false)
    const [getCollectionProducts, { loading, error, data }] = useLazyQuery(GET_COLLECTION_PRODUCTS);
    const [product, setProduct] = useState([])
    useEffect(() => {
            if(data){
                let collectionIDs = [], productIDs = []
                data.nodes.forEach((node,index) => {
                    collectionIDs.push({id: node.id})
                    const productIDArray = node.products.edges.map((product,pIndex) => {
                        if(!index && !pIndex){
                            return {id:normalizeShopifyID(product.node.id), options:product.node.options}
                        }else{
                            return {id:normalizeShopifyID(product.node.id)}
                        }
                    })
                    productIDs = productIDs.concat(productIDArray)
                })
                const variantsTriggers = {
                    if:{
                      all:[
                          {fact:'cartProducts',operator: 'inCollection',value: productIDs,collectionIDs: collectionIDs },
                          {fact:'cartProducts',operator: productIDs[0].options[0].name, value: productIDs[0].options[0].values[0]}
                      ]
                    },
                    then: {
                      all:[
                        {fact:'upsellVariant',operator: productIDs[0].options[0].name,value: productIDs[0].options[0].values[0]}
                      ]
                    },
                    swap: false,
                    type: state?.variantsTriggers?.type
                  }
                setState(update(state,{variantsTriggers:{$set:variantsTriggers}}))
            }
      }, [data]);
      useEffect(() => {
        if(product.length>0){
        
            const variantsTriggers = {
                if:{
                  all:[
                      {fact:'cartProducts',operator: 'hasAny',value: product},
                      {fact:'cartProducts',operator: product[0].options[0].name, value: product[0].options[0].values[0]}
                  ]
                },
                then: {
                  all:[
                    {fact:'upsellVariant',operator: product[0].options[0].name,value: product[0].options[0].values[0]}
                  ]
                },
                swap: false,
                type: state?.variantsTriggers?.type
              }
            setState(update(state,{variantsTriggers:{$set:variantsTriggers}}))
        
        
        }  
    }, [product]);
    const normalizeShopifyID = (id) => {
            return Number(
              String(id)
                .replace('gid://shopify/ProductVariant/', '')
                .replace('gid://shopify/Product/', '')
            );
    }   
    const productOperatorOptions = [
        {label: 'Has any', value: 'hasAny'},
        {label: 'In Collection', value: 'inCollection'},
    ]
    const ifVariantsOptionsArray = []
    state?.variantsTriggers?.if?.all && state.variantsTriggers.if.all.forEach((item, index) => {
        if(index){
            ifVariantsOptionsArray.push(state.variantsTriggers.if.all[0].value ? state.variantsTriggers.if.all[0].value[0].options.map(option => {return {label: option.name, value: option.name}}):[{label: item.operator, value: item.operator}])
            
        }
    })
    const ifVariantsValueOptionsArray = []
    state?.variantsTriggers?.if?.all && state.variantsTriggers.if.all.forEach((item, index) => {
        let values = state.variantsTriggers.if.all[0].value ? state.variantsTriggers.if.all[0].value[0].options[0].values:['']
        
        if(index){
            if(state.variantsTriggers.if.all[0].value && state.variantsTriggers.if.all[0].value[0].options.find( o => o.name === item.operator) !== undefined){
                values = state.variantsTriggers.if.all[0].value[0].options.find( o => o.name === item.operator).values
            }
            ifVariantsValueOptionsArray.push(values.map(v => {return {label: v, value: v}}).concat([{label: 'Any', value: 'Any'}]))
            
            
        }
    } )
    const thenVariantsOptionsArray = []
    state?.variantsTriggers?.then?.all && state.variantsTriggers.then.all.forEach((item) => {
        thenVariantsOptionsArray.push(state.variantsTriggers.if.all[0].value ? state.variantsTriggers.if.all[0].value[0].options.map(option => {return {label: option.name, value: option.name}}):[{label: item.operator, value: item.operator}])
    })
    const thenVariantsValueOptionsArray = []
    state?.variantsTriggers?.then?.all && state.variantsTriggers.then.all.forEach((item) => {
        let values = state.variantsTriggers.if.all[0].value ? state.variantsTriggers.if.all[0].value[0].options[0].values:['']
        if(state.variantsTriggers.if.all[0].value && state.variantsTriggers.if.all[0].value[0].options.find( o => o.name === item.operator)!== undefined){
            values = state.variantsTriggers.if.all[0].value[0].options.find( o => o.name === item.operator).values
        }
        thenVariantsValueOptionsArray.push(values.map(v => {return {label: v, value: v}}).concat([{label: 'Match cart', value: 'Match cart'},{label: 'All other options', value: 'All other options'},{label: 'Next more expensive variant', value: 'Next more expensive variant'}]))
        
    } )
    
    const handleIfVariantsOptionsChange = (index, value) => {
        setState(update(state,{variantsTriggers:{if:{all:{[index]:{operator: {$set: value}}}}}}))  
    }
    const handleCartProductsChange = (index, value) => {
        const variantsTriggers = {
            if:{
              all:[
                  {fact:'cartProducts',operator: value, value: null},
                  {fact:'cartProducts',operator: 'Variant', value: null}
              ]
            },
            then: {
              all:[
                {fact:'upsellVariant',operator: 'Variant',value: null}
              ]
            },
            swap: false,
            type: state?.variantsTriggers?.type
          }
        setState(update(state,{variantsTriggers:{$set:variantsTriggers}}))
    }
    const handleIfVariantsValueOptionsChange = (index, value) => {
        setState(update(state,{variantsTriggers:{if:{all:{[index]:{value: {$set: value}}}}}}))  
    }
    const handleThenVariantsOptionsChange = (index, value) => {
        setState(update(state,{variantsTriggers:{then:{all:{[index]:{operator: {$set: value}}}}}}))  
    }
    const handleThenVariantsValueOptionsChange = (index, value) => {
        setState(update(state,{variantsTriggers:{then:{all:{[index]:{value: {$set: value}}}}}}))  
    }
    const addIfCondition = () => {
        setState(update(state,{variantsTriggers:{if:{all:{$push: [state.variantsTriggers.if.all[1]]}}}}))
    }
    const addThenCondition = () => {
        setState(update(state,{variantsTriggers:{then:{all:{$push: [state.variantsTriggers.then.all[0]]}}}}))
    }
    const handleSwapChange = (newChecked) => {
        setState(update(state,{variantsTriggers:{swap:{$set: newChecked}}}))
    }
    return (
        <div style={{marginTop:'10px'}}>
            <FormLayout>
                <Stack wrap={false} alignment="leading">
                    <Stack.Item>
                     <IfThenLabel>
                        <Button>
                            IF
                        </Button>
                     </IfThenLabel>
                    </Stack.Item>
                    <Stack.Item fill>
                    {state?.variantsTriggers?.if?.all && state.variantsTriggers.if.all.map((trigger, index) => {
                        return <Card sectioned key={index}>
                            <Stack wrap={false}>
                            <Stack.Item>
                                <TextWrapper>
                                    <TextStyle variation="subdued">Cart product</TextStyle>
                                </TextWrapper>
                            </Stack.Item>
                            <Stack.Item>
                            { index === 0 ?
                            <Select
                                options={productOperatorOptions}
                                onChange={(value) => handleCartProductsChange(index, value)}
                                value={trigger.operator}
                                />
                                :
                            <Select
                                options={ifVariantsOptionsArray[index-1]}
                                onChange={(value) => handleIfVariantsOptionsChange(index, value)}
                                value={trigger.operator}
                                />
                            }
                            </Stack.Item>
                            <Stack.Item fill>
                            { index === 0 ?
                                <ProductPicker>
                                    { ("inCollection" === trigger.operator) ?
                                        <div className={!trigger.value ? 'mockInputContainer mockInputContainer-error' : 'mockInputContainer'}>
                                            <div>
                                                <div 
                                                    className='mockInput'
                                                    onClick={() => {setProductPickerShow('collection'+index);setCollectionPickerVisible(true)}}
                                                    >
                                                    { 
                                                    !trigger.collectionIDs ?
                                                        <span>Select collections</span> :
                                                        <div>
                                                        <span>{trigger.collectionIDs.length} collections</span>
                                                        <span> | Edit </span>
                                                        </div>
                                                    }
                                                </div>
                                                {'collection'+index === productPickerShow ?
                                                <MulProductPickerModal
                                                    shop={shop}
                                                    host={host}
                                                    token={token}
                                                    resourceType="Collection"
                                                    open={collectionPickerVisible}
                                                    initialSelectionIds={trigger.collectionIDs?trigger.collectionIDs:[]}
                                                    showVariants={false}
                                                    onSelect={(selectPayload) => {
                                                        const collectionIDs = selectPayload.selection.map(item => item.id)
                                                        getCollectionProducts({variables:  {ids: collectionIDs}})
                                                        
                                                        //handleChange(index, orIndex, selectPayload.selection.map(item => {return {'id':item.id}}), "collection")
                                                        
                                                    }}
                                                    onClose={() => {setCollectionPickerVisible(false);setProductPickerShow('product')}}
                                                    key={index}
                                                    />
                                                    :
                                                    false
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className={!trigger.value ? 'mockInputContainer mockInputContainer-error' : 'mockInputContainer'}>
                                            <div>
                                                <div 
                                                    className='mockInput'
                                                    onClick={() => {setProductPickerShow('mulProducts-'+index);setMulProductPickerVisible(true)}}
                                                    >
                                                    { 
                                                    !trigger.value ?
                                                        <span>Select products</span> :
                                                        <div>
                                                        <span>{trigger.value.length} products</span>
                                                        <span> | Edit </span>
                                                        </div>
                                                    }
                                                </div>
                                                {'mulProducts-'+index === productPickerShow ?
                                                <MulProductPickerModal
                                                    shop={shop}
                                                    host={host}
                                                    token={token}
                                                    resourceType="Product"
                                                    open={mulProductPickerVisible}
                                                    initialSelectionIds={trigger.value?trigger.value:[]}
                                                    showVariants={true}
                                                    onSelect={(selectPayload) => {                                                        
                                                        if (selectPayload.selection.length === 0) {
                                                        if (trigger.value) {
                                                          
                                                        }
                                                        return
                                                        }
                                                        setProduct(selectPayload.selection.map(item => {return {'id':item.id, options: item.options}}))
                                                    }}
                                                    onClose={() => {setMulProductPickerVisible(false);setProductPickerShow('product')}}
                                                    key={index}
                                                    />
                                                    :
                                                    false
                                                }
                                            </div>
                                        </div>
                                        }
                                </ProductPicker>
                                :
                                <Select
                                options={ifVariantsValueOptionsArray[index-1]}
                                onChange={(value) => handleIfVariantsValueOptionsChange(index, value)}
                                value={trigger.value}
                                />
                            }
                            </Stack.Item>
                            </Stack>
                        </Card>
                    })
                    }
                    {state?.variantsTriggers?.if?.all[0].value && (state.variantsTriggers.if.all[0].value[0].options.length > state.variantsTriggers.if.all.length-1) ?
                    <div style={{marginTop:'20px'}}>
                            <Button primary onClick={() => addIfCondition()}>
                                + AND
                            </Button>
                    </div>
                    :
                    false
                    }
                    </Stack.Item>
                    
                </Stack>
                <Stack wrap={false} alignment="leading">
                    <Stack.Item>
                     <IfThenLabel>
                        <Button>
                            THEN
                        </Button>
                     </IfThenLabel>
                    </Stack.Item>
                    <Stack.Item fill>
                    {state?.variantsTriggers?.then?.all && state.variantsTriggers.then.all.map((trigger, index) => {
                        return <Card sectioned key={index}>
                            <Stack wrap={false}>
                            <Stack.Item>
                                <TextWrapper>
                                    <TextStyle variation="subdued">Upsell product</TextStyle>
                                </TextWrapper>
                            </Stack.Item>
                            <Stack.Item>
                           
                            <Select
                                options={thenVariantsOptionsArray[index]}
                                onChange={(value) => handleThenVariantsOptionsChange(index, value)}
                                value={trigger.operator}
                                />
                           
                            </Stack.Item>
                            <Stack.Item fill>
                                <Select
                                options={thenVariantsValueOptionsArray[index]}
                                onChange={(value) => handleThenVariantsValueOptionsChange(index, value)}
                                value={trigger.value}
                                />
                            </Stack.Item>
                            </Stack>
                        </Card>
                    })
                    }
                    {state?.variantsTriggers?.if?.all && state.variantsTriggers.if.all[0].value && (state.variantsTriggers.if.all[0].value[0].options.length > state.variantsTriggers.then.all.length) ?
                    <div style={{marginTop:'20px'}}>
                            <Button primary onClick={() => addThenCondition()}>
                                + AND
                            </Button>
                    </div>
                    :
                    false
                    }
                    </Stack.Item>
                </Stack>
                {
                <Checkbox
                    label="Swap product in cart with upsell product variant if added"
                    checked={state?.variantsTriggers?.swap}
                    onChange={handleSwapChange}
                />
                }
            </FormLayout>
        </div>
    )
}

export default AIVariantsTriggers;